import React from 'react';
import SencoVideo from './SencoVideo';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import TermsAndCondition from './TermsAndCondition';

function App() {
  return (
    <div >
           <Router>
            <Routes>
              <Route path="/" element={ <SencoVideo />} />
              {/* <Route path="/?accesskey=:key" element={ <SencoVideo />} /> */}
              <Route path="/" element={<SencoVideo />} />
              <Route path="/TermsAndCondition" element={<TermsAndCondition />} />
            </Routes>
          </Router>
    </div>
  );
}

export default App;

import React, { useState, useEffect } from "react";
import "./sencovideo.css";
import ReactPlayer from "react-player";
import { ReactComponent as Whatsappicon } from "./images/whatsapp_icon-icons.com_65542.svg";
import { ReactComponent as Facebookicon } from "./images/icons8-facebook.svg";
// import { ReactComponent as Instagramicon } from "./images/INSTAGRAM_icon-icons.com_65535.svg";
import { ReactComponent as Twittericon } from "./images/icons8-twitterx(1).svg";
import { ReactComponent as Downloadicon } from "./images/Group 47.svg";
import ceo from "./images/suv-2.png";
import director from "./images/joi-2.png";
import preist from "./images/laxmi-2.png";
import directorLabel from "./images/Senco 05.11.00_01_57_07.Still021.png";
import preistLabel from "./images/Senco 05.11.00_02_14_21.Still005.png";
import ceoLabel from "./images/Senco 05.11.00_01_57_24.Still020.png";
import webIcon from "./images/web-icon.png";
import { useNavigate, useLocation } from "react-router-dom";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

const SencoVideo = () => {
  
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const accesskey = params.get("accesskey");


  const navigate = useNavigate();
  const [videoUrl, setVideoUrl] = useState("");
  const [data, setData] = useState([]);
  const [city, setCity] = useState("");
  const [address, setAddress] = useState([]);
  const [isAddressVisible, setIsAddressVisible] = useState(false);

  useEffect(() => {
    // Fetch the video URL from your API when the component mounts
    fetch(
      `https://api.sencocelebrations.com/api/get_video_url/?access_key=${accesskey}`
    )
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        } else {
          document.getElementById("videoApiError").textContent =
            "Invalid Access key";
        }
      })
      .then((data) => {
        if (data.status === "success") {
          console.log(data);
          setVideoUrl(data.video_url);
        } else {
          document.getElementById("videoApiError").textContent = data.msg;
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, [accesskey]);

  useEffect(() => {
    // Fetch the video URL from your API when the component mounts
    fetch("https://api.sencocelebrations.com/api/get_store_city_list/")
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setData(data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  // useEffect(() => {
  //   populateStates();
  // }, [data]);

  // Function to populate the "State" dropdown
  // function populateStates(e) {
  //   var stateDropdown = document.getElementById("state");

  //   for (var state in data) {
  //     var option = document.createElement("option");
  //     option.value = state;
  //     option.text = state;
  //     stateDropdown.appendChild(option);
  //   }

  // }

  // Function to populate the "City" dropdown based on the selected "State"
  // function populateCities(e) {
  //   var stateDropdown = document.getElementById("state");
  //   var cityDropdown = document.getElementById("city");
  //   cityDropdown.innerHTML = '<option value="">Select City</option';

  //   var selectedState = stateDropdown.value;
  //   if (selectedState in data) {
  //     // Access data from the global variable
  //     var cities = data[selectedState];
  //     for (var city in data) {
  //       var option = document.createElement("option");
  //       option.value = city;
  //       option.text = city;
  //       cityDropdown.appendChild(option);
  //     }
  //   }
  //   console.log(e.target.value)
  //   setState(e.target.value)
  // }

  // Function to populate the "Store" dropdown based on the selected "City"
  // function populateStores(e) {
  //   var cityDropdown = document.getElementById("city");
  //   var storeDropdown = document.getElementById("store");
  //   storeDropdown.innerHTML = '<option value="">Select Store</option>';

  //   var selectedState = document.getElementById("state").value;
  //   var selectedCity = cityDropdown.value;
  //   if (selectedState in data && selectedCity in data[selectedState]) {
  //     var stores = data[selectedState][selectedCity];
  //     for (var i = 0; i < stores.length; i++) {
  //       var option = document.createElement("option");
  //       option.value = stores[i];
  //       option.text = stores[i];
  //       storeDropdown.appendChild(option);
  //     }
  //   }
  //   console.log(e.target.value)
  //   setCity(e.target.value)
  // }

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsAddressVisible(true);
    fetch(
      `https://api.sencocelebrations.com/api/get_store_address/?city=${city}`
    )
      .then((response) => response.json())
      .then((data) => {
        console.log(data.result);
        setAddress(data.result);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const redirectToSencoWebsite = () => {
    window.open("https://sencogoldanddiamonds.com/", "_blank");
  };

  const downloadVideo = () => {
    fetch(videoUrl, {
      method: "GET",
      responseType: "arraybuffer",
    })
      .then((response) => response.arrayBuffer())
      .then((data) => {
        const blob = new Blob([data], { type: "video/mp4" });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = url;
        a.download = "pujavideo.mp4"; // Specify the desired filename
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const shareVideoViaWhatsApp = () => {
    const pageurl = window.location.href;
    const videoShareMessage = pageurl; // Include a custom message if needed

    // Attempt to open WhatsApp app
    const whatsappAppURL = `whatsapp://send?text=${encodeURIComponent(
      videoShareMessage
    )}`;
    window.open(whatsappAppURL, "_blank");
    // Fallback: Open WhatsApp Web if the app is not opened
    setTimeout(() => {
      const whatsappWebURL = `https://web.whatsapp.com/send?text=${encodeURIComponent(
        videoShareMessage
      )}`;
      window.open(whatsappWebURL);
    }, 2000); // Adjust the timeout as needed
  };

  // const shareVideoViaTwitter = () => {
  //   const pageurl = window.location.href;

  //   const tweetMessage = pageurl; // Include a custom message if needed

  //   // Attempt to open Twitter app
  //   const twitterAppURL = `twitter://post?message=${encodeURIComponent(
  //     tweetMessage
  //   )}`;

  //   // Open Twitter app or fallback to Twitter Web
  //   window.open(twitterAppURL, "_blank");

  //   // Fallback: Open Twitter Web after a short delay
  //   setTimeout(() => {
  //     const twitterWebURL = `https://twitter.com/intent/tweet?text=${encodeURIComponent(
  //       tweetMessage
  //     )}`;
  //     window.open(twitterWebURL, "_blank");
  //   }, 2000); // Adjust the timeout as needed
  // };
  const shareVideoViaTwitter = () => {
    const pageurl = window.location.href;

    const tweetMessage = pageurl; // Include a custom message if needed

    //const encodedMessage = encodeURIComponent(tweetMessage);

    const twitterWebURL = `https://twitter.com/intent/tweet?text=${encodeURIComponent(
      tweetMessage
    )}`;

    window.open(twitterWebURL, "_blank");
  };
  const shareVideoViaFacebook = () => {
    const pageurl = window.location.href;

    // Open the Facebook sharer link
    const facebookSharerURL = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
      pageurl
    )}`;
    window.open(facebookSharerURL, "_blank");
  };

  return (
    <div className="main-container">
      <div className="navbar">
        <div className="logowrapper">
          <img
            onClick={redirectToSencoWebsite}
            src="/logo with website.png"
            alt=""
          />
        </div>

        <div className="site-link">
          <p onClick={redirectToSencoWebsite}> Visit Senco Website </p>
          <img src={webIcon} alt="webIcon" onClick={redirectToSencoWebsite} />
        </div>
      </div>

      <div className="section">
        <div className="mainsection">
          <div className="leftsection">
            <span id="videoApiError"></span>
            <div className="videobox">
              <div className="videowrapper">
                <ReactPlayer
                  playing={true}
                  muted={true}
                  className="videoplayer"
                  url={videoUrl}
                  controls={true}
                  width="100%"
                  height="100%"
                />
              </div>

              <div className="btnbox">
                <div className="sharebtnbox">
                  <p> Share on: </p>
                  <Whatsappicon onClick={shareVideoViaWhatsApp} />
                  <Facebookicon onClick={shareVideoViaFacebook} />
                  <Twittericon onClick={shareVideoViaTwitter} />
                </div>

                <div className="downloadbtnbox" onClick={downloadVideo}>
                  {" "}
                  <Downloadicon /> Download Video{" "}
                </div>
              </div>
            </div>
          </div>

          <div className="rightsection">
            <div className="content-box">
              <h3 id="mtitle"> Chance to WIN </h3>
              <div className="digicertwrapper">
                <img
                  src="/pujacertificate.png"
                  alt="puja digital certificate"
                />
              </div>

              <div
                className="content-res"
                style={isAddressVisible ? { display: "none" } : {}}
              >
                <h3 id="title"> Chance to WIN </h3>
                <p id="intropara">
                  {" "}
                  Ma Lakshmi's golden blessing Pooja video and a personalized
                  certificate with your name, plus a chance to win a Senco gold
                  coin.
                </p>

                <form className="formbox" onSubmit={handleSubmit}>
                  {/* <div className="selectfield">
                    <span>State:</span>
                    <select id="state" onChange={(e)=>populateCities(e)}>
                      <option>Select State</option>
                    </select>
                  </div> */}
                  {/* <div className="selectfield">
                    <select id="city" onChange={(e)=>setCity(e.target.value)} >
                      <option>Select City</option>
                      {data.map((city)=>(
                        <option value={city}>{city}</option>
                      ))}
                    </select>
                   
                  </div> */}
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    onChange={(value, newValue) => {
                      setCity(newValue);
                    }}
                    options={data}
                    sx={{ width: 250 }}
                    renderInput={(params) => (
                      <TextField {...params} label="City" />
                    )}
                  />
                  {/* <div className="selectfield"> 
                    <span >Store:</span>
                    <select onChange={(e)=>setStore(e.target.value)} id="store">
                      <option>Select Store</option>
                    </select> 
                   
                      </div> */}
                  <button type="submit" className="signupbtn">
                    {" "}
                    FIND STORES{" "}
                  </button>
                </form>
              </div>

              <div
                className="content-res"
                style={!isAddressVisible ? { display: "none" } : {}}
              >
                <h3 id="title"> PLEASE VISIT</h3>
                <p id="intropara">
                  You can receive your golden blessing from this SENCO showroom
                </p>
                <div className="addressWrapper">
                  {address.map((location, index) => (
                    <div>
                      <span>
                        {index + 1}: {location.store_address}
                      </span>
                      <br />
                      <span> {location.state}</span>
                      <br />
                      <span>{location.contact_details}</span>
                      <br />
                      <br />
                    </div>
                  ))}
                </div>
                <button
                  className="signupbtn"
                  style={{
                    color: "#e7162d",
                    backgroundColor: "white",
                    border: "1px solid ",
                  }}
                  onClick={() => setIsAddressVisible(false)}
                >
                  {" "}
                  GO BACK
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        {" "}
        <div className="image-section">
          <div className="left-img-section">
            <div className="ceo-section">
              <img src={ceo} alt="" className="ceo-pic" />
              <img alt="" src={directorLabel} className="ceo-label" />
            </div>
            <div className="direcror-section">
              <img src={director} alt="diractor" className="director-pic" />
              <img alt="director" src={ceoLabel} className="director-label" />
            </div>
          </div>
          <div className="termstexts">
            <p onClick={() => navigate("/TermsAndCondition")}>
              {" "}
              <sup>*</sup> Terms and Conditions apply{" "}
            </p>
          </div>
          <div className="priest-section">
            <img src={preist} alt="preist pic" className="preist-pic" />
            <img
              alt="preist label pic"
              src={preistLabel}
              className="priest-label"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SencoVideo;
